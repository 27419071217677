import React from 'react';
import { withPrefix, WrapPageElementBrowserArgs } from 'gatsby';
import {
  I18NextContext,
  LANGUAGE_KEY,
  PageContext,
  PluginOptions,
  LocaleNode,
  Resource,
  ResourceKey
} from '../types';
import i18next, { i18n as I18n } from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { I18nextContext as I18nextContextType } from '../i18nextContext';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const withI18next = (i18n: I18n, context: I18NextContext) => (children: React.ReactNode) => (
  <I18nextProvider i18n={i18n}>
    <I18nextContextType.Provider value={context}>{children}</I18nextContextType.Provider>
  </I18nextProvider>
);

const removePathPrefix = (pathname: string, stripTrailingSlash: boolean) => {
  const pathPrefix = withPrefix('/');
  let result = pathname;

  if (pathname.startsWith(pathPrefix)) {
    result = pathname.replace(pathPrefix, '/');
  }

  if (stripTrailingSlash && result.endsWith('/')) {
    return result.slice(0, -1);
  }

  return result;
};

const defaultLanguage: string = process.env.GATSBY_LANGUAGES_DEFAULTLANGUAGE || "en";
const languages: string[] = (process.env.GATSBY_LANGUAGES_LANGUAGES || "").split(',');
const siteUrl: string = process.env.GATSBY_URL || `http://localhost:8000`;

const plugOptions: PluginOptions = {
  localeJsonNodeName: "locales",
  languages: languages,
  defaultLanguage: defaultLanguage,
  generateDefaultLanguagePage: false,
  redirect: true,
  siteUrl: siteUrl,
  i18nextOptions: {
    fallbackLng: defaultLanguage,
    supportedLngs: languages,
    defaultNS: 'components',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    keySeparator: '.',
    nsSeparator: ':',
    ignoreJSONStructure: true,
    debug: false,
    ns: ['components', 'pages'],
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}/{{filename}}.json`, // Path to load files
      allowMultiLoading: true, // Allow loading multiple files in the same namespace
    },
  },
  backend: {
    loadPath: `/locales/{{lng}}/{{ns}}/{{filename}}.json`, // Path to load translations
  },
  pages: [
    {
      matchPath: '/:lang?/blog/blogspot/:uid',
      getLanguageFromPath: true,
      excludeLanguages: languages.filter(l => l !== defaultLanguage)
    },
    {
      matchPath: '/:lang?/(.*)',
      getLanguageFromPath: true,
      excludeLanguages: languages.filter(l => l !== defaultLanguage)
    }
  ]
};

export const wrapPageElement = (
  { element, props }: WrapPageElementBrowserArgs<any, PageContext>,
  pOptions: PluginOptions
) => {
  if (!props || !props.pageContext || !props.pageContext.i18n) return;

  pOptions = plugOptions;

  i18next.use(LanguageDetector).init(pOptions.i18nextOptions);

  const { data, pageContext, location } = props;
  const { routed, language, languages, originalPath, defaultLanguage, path } = pageContext.i18n;
  const { generateDefaultLanguagePage, localeJsonNodeName, siteUrl } = pOptions;
  const isRedirect = pOptions.redirect && !routed;

  if (isRedirect) {
    const { search } = location;

    if (typeof window !== 'undefined') {
      let detected =
        window.localStorage.getItem(LANGUAGE_KEY) ||
        i18next.language;

      if (!languages.includes(detected)) {
        detected = language;
      }

      window.localStorage.setItem(LANGUAGE_KEY, detected);

      if (detected !== defaultLanguage) {
        const queryParams = search || '';
        const stripTrailingSlash = pOptions.trailingSlash === 'never';
        const newUrl = withPrefix(
          `/${detected}${removePathPrefix(location.pathname, stripTrailingSlash)}${queryParams}${location.hash
          }`
        );
        // @ts-ignore
        window.___replace(newUrl);
        return null;
      }
    }
  }

  const localeJNName = localeJsonNodeName || "locales";
  const localeNodes: Array<{ node: LocaleNode }> = data?.[localeJNName]?.edges || [];

  if (languages.length > 1 && localeNodes.length === 0 && process.env.NODE_ENV === 'development') {
    console.error(
      `
      No translations were found in "${localeJNName}" key for "${originalPath}". 
      You need to add a graphql query to every page like this:
      
      export const query = graphql\`
        query($language: String!) {
          ${localeJNName}: allLocale(language: {eq: $language}) {
            edges {
              node {
                ns
                data
                language
              }
            }
          }
        }
      \`;
      `
    );
  }

  const namespaces = localeNodes.map(({ node }) => node.ns);

  let defaultNS = "components";
  defaultNS = namespaces.find((ns) => ns !== defaultNS) || defaultNS;
  const fallbackNS = namespaces.filter((ns) => ns !== defaultNS);

  const resources: Resource = localeNodes.reduce<Resource>((res: Resource, { node }) => {
    const parsedData: ResourceKey = typeof node.data === 'object' && node.data !== null ? node.data : JSON.parse(node.data);
  
    // Ensure the language key exists
    if (!(node.language in res)) {
      res[node.language] = {};
    }
  
    const existingNamespaceData = (res[node.language][node.ns] as Record<string, any>) || {};
    const validParsedData = (parsedData as Record<string, any>) || {};
    
  
    // Perform safe spread operations by ensuring both objects are valid
    res[node.language][node.ns] = {
      ...existingNamespaceData, // Spread existing namespace data (if it's an object)
      ...validParsedData, // Spread the new parsed data (if it's an object)
    };
  
    return res;
  }, {});
  
  
  
  const i18n = i18next.createInstance();

  i18n.init({
    ...pOptions.i18nextOptions,
    resources,
    lng: language,
    fallbackLng: defaultLanguage,
    defaultNS,
    fallbackNS,
  });

  if (i18n.language !== language) {
    i18n.changeLanguage(language);
  }

  const context: I18NextContext = {
    routed,
    language,
    languages,
    originalPath,
    defaultLanguage,
    generateDefaultLanguagePage,
    siteUrl,
    path
  };

  return withI18next(i18n, context)(element);
};
